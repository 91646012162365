<template>
  <section class="bg_color">
    <h2>ルール</h2>
    <section class="inner p0">
      <div class="box_lst mt0 slide moter_lst pb0">
        <h4 class="ttl_lst">
          <div class="inner tac heading_icon">
            <span class="moter_icon">1.登録可能モーターについて</span>
          </div>
        </h4>
        <ul class="box_txt-list">
          <li>
            ・BOAT RACE蒲郡で使用される6モーターまでが登録可能となります。
          </li>
          <li>・一度登録したモーターの変更はできません。</li>
          <li>
            ・１メールアドレス1アカウントでの参加のみとし、複数アカウントでのご参加はランキングや賞品受け取り対象外とさせていただきます。
          </li>
          <li>
            ・一度に6モーターを登録する必要はありません。<br />時期をおいて数モーターずつ選択することもできます。
          </li>
        </ul>
        <h4 class="ttl_lst">
          <div class="inner tac heading_icon icon_coin">
            <span class="moter_icon">2.ポイント加算について</span>
          </div>
        </h4>
        <ul class="box_txt-list">
          <li>
            ・BOATRACEOfficialの発表に基づきモグーを計算し、ポイント加算が行われます。(例:獲得賞金10万円→10万モグー獲得)
          </li>
          <li>・モーターを登録してからでないと、ポイント加算されません。</li>
          <li>・レース実績がある場合のみ、ポイント加算されます。</li>
          <li>・完走手当についてはポイント換算されません。</li>
          <li>
            ・その他イレギュラーな結果については、MOG運営事務局の判断により対応をする場合があります。
          </li>
        </ul>
        <h4 class="ttl_lst">
          <div class="inner tac heading_icon icon_cal">
            <span class="moter_icon">3.集計対象レース・期間</span>
          </div>
        </h4>
        <ul class="box_txt-list">
          <li>
            ・モグー加算の対象レースはボートレース蒲郡のレースのみになります。
          </li>
          <li>
            ・対象となるレースグレードは、<span class="txt-red"
              >ボートレース蒲郡</span
            >で行われる<span class="txt-red">一般戦・G&#8546;</span
            >になります。※SG,G&#8544;,G&#8545;は対象外
          </li>
          <li>
            ・集計の対象となる期間は、2024年7月16日(火)から、翌年のモーター入替までとなります。<br />
          </li>
          <li class="no-indent">
            ・モーター登録期間は2024年7月16日(火)から2024年10月25日(金)までとなります。<br />
            <span class="txt-red"
              >※2025年のモーター入替時期は現時点で未定です。</span
            >
            <div class="rule_box">
              <p class="rule_img">
                <img src="@/assets/images/rule/img_term.png" alt="" />
              </p>
            </div>
            <p class="rule_note">
              モーターの精度がわかる登録期間後半の方が良いモーターを選びやすいですが、Yさんのように遅く選ぶほど、早く選んだXさんとの差が広がっていってしまいます。<br /><br />
              <span class="txt-red"
                >追加斡旋やモーターの故障による変更など、モーター抽選結果に無いモーターが何らかの理由で出走していても選択は出来ません。モグー加算もされません。</span
              >
            </p>
          </li>
        </ul>
      </div>
    </section>
  </section>
</template>

<script>
export default {
  name: "Rule",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css" src="@/assets/css/style_parts.css" scoped></style>
<style lang="css" src="@/assets/css/style_ranking.css" scoped></style>
<style lang="css" src="@/assets/css/style_select.css" scoped></style>
